/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/foundation-sites@6.7.3/dist/js/foundation.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/webfontloader@1.6.28/webfontloader.min.js
 * - /npm/what-input@5.2.10/dist/what-input.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
